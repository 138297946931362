import {
    HTTP
} from '../utils/axios'

class CommentModel extends HTTP {
    /*首页渲染*/
    index(data) {
        return this.request({
            url: 'board/comments',
            method: "GET",
            data: data
        })
    }

    /*创建*/
    create(value) {
        return this.request({
            url: "board/comments/",
            method: "POST",
            data: value,
        })
    }

    /*修改*/
    update(value, id) {
        return this.request({
            url: "board/comments/" + id,
            method: "PATCH",
            data: value,
        })
    }

    /*删除*/
    destroy(id) {
        return this.request({
            url: "board/comments/" + id,
            method: "DELETE"
        })
    }
}

export {
    CommentModel
}