<template>
  <div>
    <a-form layout="inline" :form="form1" @submit="handleSearch">
      <a-form-item>
        <a-input
          v-decorator="['key']"
          placeholder="请输入评论内容"
          autocomplete="off"
        >
        </a-input>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" html-type="submit"> 搜索 </a-button>
      </a-form-item>
      <a-form-item>
        <a-button type="danger" @click="handleClear" html-type="button">
          清空
        </a-button>
      </a-form-item>
    </a-form>
    <a-table
      :columns="columns"
      :dataSource="data"
      :pagination="false"
      :loading="loading"
      :scroll="{ x: 1600 }"
    >
      <template slot="visible" slot-scope="text, record">
        <a-switch :checked="text" @change="onChange(record)" />
      </template>
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="handleDelete(record)" class="delete"
          >删除</a
        >
      </span>
    </a-table>
    <Pagination
      :current="current"
      :total="total"
      @handleChange="handleChange"
      v-show="total > 20"
    />
  </div>
</template>

<script>
import { CommentModel } from "@/models/comment";
import Pagination from "@/components/Pagination";
import formatTime from "@/utils/formatTime";
import userNameDisplay from "@/utils/userNameDisplay";
const commentModel = new CommentModel();

export default {
  name: 'CommentList',
  components: {
    Pagination
  },
  data() {
    return {
      form1: this.$form.createForm(this, {
        name: "comment_list",
      }),
      data: [],
      visible: false,
      editId: 0,
      loading: false,
      total: 0,
      current: 1,
    };
  },
  created() {
    this.queryCommentList()
  },
  computed: {
    columns() {
      return [
        {
          title: "账号",
          dataIndex: "user_name",
          key: "user_name",
        },
        {
          title: "昵称",
          dataIndex: "nick_name",
          key: "nick_name",
          width: 100,
        },
        {
          title: "绑定邮箱",
          dataIndex: "email",
          key: "email",
        },
        {
          title: "评论类型",
          dataIndex: "recordable_type_display",
          key: "recordable_type_display",
          width: 100
        },
        {
          title: "对象名称",
          dataIndex: "recordable",
          key: "recordable",
          width: 200,
          ellipsis: true,
        },
        {
          title: "评论内容",
          dataIndex: "comment",
          key: "comment",
          width: 400,
          ellipsis: true,
        },
        {
          title: "评论时间",
          dataIndex: "created_at",
          key: "created_at",
        },
        {
          title: "是否显示",
          dataIndex: "visible",
          key: "visible",
          scopedSlots: { customRender: "visible" },
        },
        {
          title: "操作",
          dataIndex: "",
          key: "action",
          scopedSlots: {
            customRender: "action",
          },
        }
      ]
    }
  },
  methods: {
    onChange(record) {
      commentModel
        .update({ visible: !record.visible }, record.id)
        .then((res) => {
          this.$notification.open({
            message: "提示",
            description: res.message,
            duration: 2,
          })
          record.visible = !record.visible;
        })
    },

    handleChange(page) {
      this.current = page
      this.queryCommentList("", null, page)
    },

    handleSearch(e) {
      e.preventDefault()
      this.form1.validateFields((err, values) => {
        if (!err) {
          let key = values.key ? values.key.trim() : ""
          this.queryCommentList(key, null, 1)
        }
      })
    },

    handleDelete(record) {
      const confirmText = ["确定删除当前评论内容吗？", "删除后将不可恢复"]
      const newDatas = []
      const h = this.$createElement
      for (const i in confirmText) {
        newDatas.push(h("p", null, confirmText[i]))
      }
      this.$confirm({
        content: h("div", null, newDatas),
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          commentModel.destroy(record.id).then((res) => {
            if (res.code == 0) {
              this.$notification.open({
                message: "提示",
                description: res.message,
                duration: 2
              })
              this.queryCommentList()
            }
          })
        },
        onCancel() {},
      })
    },

    handleClear() {
      this.form1.resetFields()
      this.queryCommentList()
    },

    queryCommentList(key, id, page) {
      const data = {
        key: key,
        id: id,
        page: page
      }
      this.loading = true
      commentModel.index(data).then((res) => {
        if (res.code == 0) {
          this.current = page
          res.result.map((item, index) => {
            item.key = index
            item.user_name = userNameDisplay(item.user)
            item.nick_name = item.user.nick_name
            item.recordable = item.recordable.name
            item.email = item.user.email
            item.created_at = formatTime(item.created_at)
            return item
          })
          this.data = res.result
          this.loading = false
          this.total = res.total
        }
      })
    }
  }
}
</script>
